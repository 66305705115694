import React from "react";
import Icons from "../../components/Icons";
import Duration from "./Duration";

const SoundCloudPlayerWidget = (props) => {
  const {
    url,
    playing,
    controls,
    light,
    volume,
    muted,
    loop,
    played,
    loaded,
    duration,
    playbackRate,
    pip,
  } = props;
  return (
    <div className="flex-1 h-full w-full">
      <div>
        <div className="flex flex-row items-center">
          <img
            className="w-10 h-10 rounded-lg m-2"
            src="https://images.genius.com/7ea34ad2fa694fb706de3e81dc1588c4.1000x1000x1.jpg"
            // source={{
            //   uri: img,
            //   // uri: 'https://images.genius.com/7ea34ad2fa694fb706de3e81dc1588c4.1000x1000x1.jpg',
            // }}
          />
          <div className="flex-1 flex flex-row items-center">
            <div className="flex-1">
              <div
                className="mr-auto font-medium text-sm text-text "
                numberOfLines={1}
                ellipsizeMode="tail"
              >
                title
              </div>
              <div
                className="mr-auto font-medium text-xs text-text2"
                numberOfLines={2}
                ellipsizeMode="tail"
              >
                artist
              </div>
            </div>
            <div className="flex flex-row items-center">
              <div
              // onClick={skipToPrevious}
              >
                <img src={Icons.PlayerPrevious} width={24} height={24} />
              </div>

              <div
                className="rounded-full relative p-3"

                //   onClick={() => togglePlayback(playBackState)}
              >
                <div className="top-0 bottom-0 right-0 left-0 absolute bg-black opacity-10 rounded-full" />
                {playing ? (
                  <img src={Icons.PlayerPause} width={24} height={24} />
                ) : (
                  <img src={Icons.PlayerPlay} width={24} height={24} />
                )}
              </div>

              <div
              // onClick={skipToNext}
              >
                <img src={Icons.PlayerNext} width={24} height={24} />
              </div>
            </div>
          </div>
        </div>
        {/* <progress max={1} className='w-full h-1 rounded-full bg-primary-500' value={played} /> */}
        <div class="w-full bg-gray-200 rounded-full h-1.5">
          <div
            class="bg-primary h-1.5 rounded-full duration-700 transition-all"
            style={{ width: `${played * 100}%` }}
          ></div>
        </div>
        <div className="flex flex-row justify-between">
          <Duration seconds={duration * (1 - played)} />
          <div className="flex flex-row justify-between">
            <Duration seconds={duration * played} />/
            <Duration seconds={duration} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SoundCloudPlayerWidget;
