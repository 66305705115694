import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import Button from "../../components/button";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow_right.svg";
import { ReactComponent as StarFillIcon } from "../../assets/icons/star_fill.svg";
import AddReview from "./AddReview";
import ReviewInput from "./ReviewInput";
import ReadMore from "../../components/read-more";
import Image from "../../components/image/image";
import MessageContract from "../../MessageContract";

export const BaseUrl = process.env.REACT_APP_HOST_URL;
export const CmsUrl = process.env.REACT_APP_CMS_APP_URL;

function ReviewContent(props) {
  const search = useLocation().search;
  const params = new URLSearchParams(search);
  const [token, setToken] = useState(params.get("token"));
  const [id, setId] = useState(params.get("id"));
  const [content, setContent] = useState({});

  const [userId, setUserId] = useState(null);
  const [contentRating, setContentRating] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [messageData, setMessageData] = useState(true);

  const [showAddReviewSection, setShowAddReviewSection] = useState(false);
  const [showAddReviewForm, setShowAddReviewForm] = useState(false);

  const hasToken = token && token != null;

  useEffect(() => {
    fetchContent();
  }, []);

  const fetchContent = () => {
    console.log("fetchContent", search, params, id);

    if (id && id != null) {
      const headers = hasToken ? { Authorization: "Bearer " + token } : {};
      fetch(`${BaseUrl}/nj/jyot/app/cms_content/` + id, { headers })
        .then((res) => res.json())
        .then((data) => {
          console.log("/cms_content", data);
          setContent(data?.item || {});
          fetchRating();
        })
        .catch((err) => {
          // console.log(err.message);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  };

  const fetchRating = () => {
    console.log("fetchRating", search, params, id);

    if (id && id != null) {
      const headers = hasToken ? { Authorization: "Bearer " + token } : {};
      fetch(
        `${BaseUrl}/nj/jyot/app/analytics/user_content_rating/cms_content/` +
          id,
        { headers }
      )
        .then((res) => res.json())
        .then((data) => {
          const ratingList = data?.item?.rating || [];
          const userId = data?.item?.uid || null;
          setUserId(userId);
          setContentRating(ratingList);
          let ratingAlreadyGiven = false;
          if (token && userId) {
            if (ratingList) {
              ratingList.map((rating) => {
                if (ratingAlreadyGiven === false) {
                  ratingAlreadyGiven = rating.user_id === userId;
                }
              });
            }
          }
          setShowAddReviewSection(ratingAlreadyGiven === false);
          setIsLoading(false);
        })
        .catch((err) => {
          // console.log(err.message);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    window.addEventListener("message", onReactNativeMessageReceived);
    document.addEventListener("message", onReactNativeMessageReceived);
    return () => {
      window.removeEventListener("message", onReactNativeMessageReceived);
      document.removeEventListener("message", onReactNativeMessageReceived);
    };
  }, []);

  const onReactNativeMessageSend = (data) => {
    console.log("onReactNativeMessageSend", data);
    if (data != null) {
      setMessageData(data);
      MessageContract.onRectMessageSend(data);
    }
  };

  const onReactNativeMessageReceived = (message) => {
    try {
      const data = MessageContract.onRectMessageReceived(message);
      if (data != null) {
        setMessageData(data);
      }
    } catch (error) {}
  };

  console.log("Content - content", content, " contentRating", contentRating);
  const childProps = {
    id: id,
    token: token,
    content: content,
    userId: userId,
    contentRating: contentRating,
    isLoading: isLoading,
    messageData: messageData,
    onReactNativeMessageSend: (data) => onReactNativeMessageSend(data),
    onRefreshRating: fetchRating,
  };

  const renderCommentItem = (indent, rating, comment, childProps) => {
    return (
      <div key={comment.id_comment} style={{ marginLeft: `${indent * 15}px` }}>
        <div className="flex flex-row justify-between items-center">
          <Image
            src={
              rating.comment_user_list[comment.user_id].utype == "ADMIN"
                ? "/favicon.png"
                : rating.comment_user_list[comment.user_id].image
            }
            fallbackText={rating.comment_user_list[comment.user_id].name}
            className="w-10 h-10 rounded-full"
          />
          <div className="mr-auto ml-2 text-base font-primary-medium ">
            {rating.comment_user_list[comment.user_id].name}
          </div>
          {indent === 0 && (
            <div className="items-center justify-center">
              <div className="bg-gray-200 rounded-full w-fit p-1 flex flex-row items-center justify-center">
                <div className="px-1">
                  <StarFillIcon width={14} height={14} />
                </div>
                <div className="px-1 text-xs">
                  {(Math.round(rating.rating * 10) / 10).toFixed(1)}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="mb-4 mt-2 ml-1 text-left text-sm pt-2 flex flex-row">
          {comment.edited === true && (
            <div className="text-cyan-400 w-2 h-2 mr-1 text-lg font-semibold">
              *
            </div>
          )}
          <ReadMore
            className="text-gray-700 dark:text-gray-400 text-justify"
            textLength={100}
            text={comment.text}
          />
        </div>
        <div className="ml-1">
          <ReviewInput
            {...childProps}
            rating={rating}
            parentComment={comment}
            onLoading={(loading) => setIsLoading(loading)}
          />
        </div>
        <div className="ml-8 mt-2">
          {comment.comment_list &&
            comment.comment_list.map((sub_comment, key) =>
              renderCommentItem(indent + 1, rating, sub_comment, childProps)
            )}
        </div>
      </div>
    );
  };

  return isLoading ? (
    <div className="h-screen flex items-center justify-center text-center text-2xl font-semibold">
      <div
        className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
        role="status"
      ></div>
      <span className="ml-5">Please wait, loading...</span>
    </div>
  ) : (
    <div
      className={`${
        isMobile ? "overflow-y-scroll" : ""
      } justify-center items-center text-center w-full`}
    >
      {showAddReviewForm ? (
        <div className="mt-4 flex flex-col w-full items-center">
          <AddReview
            {...childProps}
            onClose={() => setShowAddReviewForm(false)}
          />
        </div>
      ) : (
        <div className="mt-4 flex flex-col w-full items-center">
          {contentRating && Object.entries(contentRating).length > 0 && (
            <div className="flex flex-col mb-16 -ml-4">
              <div className=" font-primary-medium text-center text-lg tracking-wider my-2 mb-7 w-fit">
                REVIEWS
              </div>
              <div
                className={`${isMobile ? "w-[calc(100vw-50px)]" : "w-[500px]"}`}
              >
                {contentRating.map((rating) => {
                  return (
                    <div>
                      {rating.comment_list &&
                        rating.comment_list.map((comment, key) =>
                          renderCommentItem(0, rating, comment, childProps)
                        )}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {showAddReviewSection && (
            <div className="max-w-[500px] w-full px-4 sticky bottom-0">
              <Button
                onClick={() => {
                  hasToken
                    ? setShowAddReviewForm(true)
                    : onReactNativeMessageSend(
                        MessageContract.AppRequest(
                          MessageContract.TypeEnum.LOGIN,
                          { id: id }
                        )
                      );
                }}
                title={"ADD YOUR REVIEW"}
                icon={<ArrowRight fill="none" stroke="orange" />}
                className="mb-6 w-full"
                simple={true}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default ReviewContent;
