import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  HashRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Updates from "./screens/updates";
import SoundCloudPlayer from "./screens/Player/SoundCloudPlayer";
import Interest from "./screens/interest";
import VideoPlayer from "./screens/video-player/VideoPlayer";
import CmsContent from "./screens";
import StaticDetails from "./screens/static";
import ReviewContent from "./screens/review";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HashRouter>
    <Routes>
      <Route exact key="content" element={<CmsContent />} path="/content" />
      <Route exact key="review" element={<ReviewContent />} path="/review" />
      <Route exact key="updates" element={<Updates />} path="/updates" />
      <Route exact key="interest" element={<Interest />} path="/interest" />
      <Route
        exact
        key="about"
        element={<StaticDetails view_type="ABOUT_US" />}
        path="/about"
      />
      <Route
        exact
        key="terms-conditions"
        element={<StaticDetails view_type="TERMS_AND_CONDITIONS" />}
        path="/terms-conditions"
      />
      <Route
        exact
        key="privacy-policy"
        element={<StaticDetails view_type="PRIVACY_POLICY" />}
        path="/privacy-policy"
      />
      <Route
        exact
        key="shipping-policy"
        element={<StaticDetails view_type="SHIPPING_POLICY" />}
        path="/shipping-policy"
      />
      <Route
        exact
        key="return-refund-policy"
        element={<StaticDetails view_type="RETURN_AND_REFUND_POLICY" />}
        path="/return-refund-policy"
      />
      <Route
        exact
        key="cancellation-policy"
        element={<StaticDetails view_type="CANCELLATION_POLICY" />}
        path="/cancellation-policy"
      />
      <Route
        exact
        key="faqs"
        element={<StaticDetails view_type="FAQS" />}
        path="/faqs"
      />
      <Route
        exact
        key="contact"
        element={<StaticDetails view_type="CONTACT_US" />}
        path="/contact"
      />
      <Route exact key="root" element={<App />} path="/" />
      <Route
        exact
        key="soundcloud-player"
        element={<SoundCloudPlayer />}
        path="/soundcloud-player"
      />
      <Route
        exact
        key="video-player"
        element={<VideoPlayer />}
        path="/video-player"
      />
    </Routes>
  </HashRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(// console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
