import React, { useEffect, useState } from "react";

export const BaseUrl = process.env.REACT_APP_HOST_URL;

function Question(props) {
  const { idx, questionInfo, selectedAnswerIdx, onSelectionChange } = props;

  console.log("Question - props", props);

  const RowWidget = ({ icon, title, value }) => {
    return (
      <div className="flex flex-row my-1">
        <div className="w-4/5 whitespace-pre-wrap">
          {icon && <img src={icon} className="h-4 w-4" alt="logo" />}
          {title && (
            <div
              className="font-primary-regular text-sm text-black ml-4"
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            ></div>
          )}
        </div>
        {value ? (
          <div className="items-center">
            <p className="font-primary-regular text-sm text-black ml-2 text-left ">
              {value}
            </p>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  };

  console.log("questionInfo", questionInfo);
  return (
    <div className="mt-10">
      <RowWidget title={`${idx + 1}. ${questionInfo.question}`} />
      <div className="ml-4 mt-5">
        {questionInfo.answer_choices.map((answer, key) => (
          <RowWidget
            key={key}
            title={answer}
            value={
              <div class="inline-flex items-center py-2">
                <label
                  class="relative flex items-center rounded-full cursor-pointer"
                  htmlFor="amber"
                >
                  <input
                    id={`ans-${key + 1}`}
                    name="color"
                    type="radio"
                    class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-slate-700 text-amber-500 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-amber-500 before:opacity-0 before:transition-opacity checked:border-amber-500 checked:before:bg-amber-500 hover:before:opacity-10"
                  />
                  <span class="absolute transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-amber-500 peer-checked:opacity-100">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-3.5 w-3.5"
                      viewBox="0 0 16 16"
                      fill="currentColor"
                    >
                      <circle data-name="ellipse" cx="8" cy="8" r="8"></circle>
                    </svg>
                  </span>
                </label>
              </div>
            }
          />
        ))}
      </div>
      <hr className="my-4 border-1" />
    </div>
  );
}

export default Question;
