import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import Button from "../../components/button";
import { ReactComponent as SendMessage } from "../../assets/icons/send_message.svg";
import moment from "moment";

import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import TextareaAutosize from "react-autosize-textarea";

export const BaseUrl = process.env.REACT_APP_HOST_URL;
export const CmsUrl = process.env.REACT_APP_CMS_APP_URL;

function CustomTextArea(props) {
  const [comment, setComment] = useState(props.value);
  const [showEmojis, setShowEmojis] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(props.value.length);

  const addEmoji = (e) => {
    let sym = e.unified.split("-");
    let codesArray = [];
    sym.forEach((el) => codesArray.push("0x" + el));
    let emoji = String.fromCodePoint(...codesArray);
    const beforeStr = comment.substring(0, cursorPosition);
    const afterStr = comment.substring(cursorPosition);
    const newValue = beforeStr + emoji + afterStr;
    setComment(newValue);
    props.onChange(newValue);
    setShowEmojis(false);
  };

  const isDisabled = comment === null || comment.trim() === "";

  return (
    <>
      <div className="w-full bg-white flex flex-row ">
        <TextareaAutosize
          name="comment"
          className={`p-2 text-gray-800 bg-orange-50 text-base h-full w-full`}
          editable={!isDisabled}
          value={comment}
          autoFocus={true}
          placeholder={isMobile ? "Type" : "Add a comment..."}
          rows={props.rows || 1}
          maxRows={props.maxRows || 3}
          onChange={(e) => {
            setCursorPosition(e.target.selectionStart);
            setComment(e.target.value);
            props.onChange(e.target.value);
          }}
          onKeyDown={(e) => setCursorPosition(e.target.selectionStart)}
          onClick={(e) => setCursorPosition(e.target.selectionStart)}
          // onResize={(e) => {
          //   console.log(e);
          // }}
        />
        <button
          className={`px-2 mt-0 bg-orange-50`}
          onClick={() => setShowEmojis(!showEmojis)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </button>
      </div>
      {showEmojis && (
        <div
          className={` absolute z-10 mt-12 ml-0 ${isMobile ? "left-2" : ""}`}
        >
          <Picker
            data={data}
            onEmojiSelect={addEmoji}
            style={{
              position: "absolute",
              marginTop: "465px",
              marginLeft: -40,
              maxWidth: "320px",
              borderRadius: "20px",
            }}
            theme="light"
          />
        </div>
      )}
    </>
  );
}

export default CustomTextArea;
