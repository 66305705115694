import React, { Component } from "react";
import PropTypes from "prop-types";

export default class Image extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fallbackText: props.fallbackText,
      src: props.src,
      errored: false,
    };
  }

  onError = () => {
    if (!this.state.errored) {
      if (this.props.fallbackSrc) {
        this.setState({
          src: this.props.fallbackSrc,
          errored: true,
        });
      } else {
        this.setState({
          errored: true,
        });
      }
    }
  };

  render() {
    const { src } = this.state;
    const { src: _1, fallbackSrc: _2, ...props } = this.props;
    if (this.state.errored && this.state.fallbackText) {
      const words = (this.state.fallbackText || " ").split(" ") || [];
      return (
        <div
          className="rounded-full bg-gray-200 p-2 font-primary-bold text-base text-slate-500 border-2 border-slate-300 "
          style={{ width: "45px", height: "45px" }}
        >
          {words.length > 0 && words[0].substring(0, 1)}
          {words.length > 1 && words[1].substring(0, 1)}
        </div>
      );
    } else {
      return <img src={src} onError={this.onError} {...props} />;
    }
  }
}

Image.propTypes = {
  src: PropTypes.string,
  fallbackSrc: PropTypes.string,
};
