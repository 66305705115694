import SearchIcon from "../assets/icons/search_normal.svg";
import Alert from "../assets/icons/alert.svg";
import Archive from "../assets/icons/archive.svg";
import ArchiveAdd from "../assets/icons/archive_add.svg";
import ArchiveAddFill from "../assets/icons/archive_add_fill.svg";
import ArchiveTick from "../assets/icons/archive_tick.svg";
import ArrowDown from "../assets/icons/arrow_down.svg";
import ArrowLeft from "../assets/icons/arrow_left.svg";
import ArrowRight from "../assets/icons/arrow_right.svg";
import ArrowSquareDown from "../assets/icons/arrow_square_down.svg";
import ArrowUp from "../assets/icons/arrow_up.svg";
import BagTick from "../assets/icons/bag_tick.svg";
import Book from "../assets/icons/book.svg";
import Bookmark from "../assets/icons/bookmark.svg";
import Bubble from "../assets/icons/bubble.svg";
import Calendar from "../assets/icons/calendar.svg";
import CalendarSelected from "../assets/icons/calendar_selected.svg";
import CalendarTick from "../assets/icons/calendar_tick.svg";
import Call from "../assets/icons/call.svg";
import CallCalling from "../assets/icons/call_calling.svg";
import Chat from "../assets/icons/chat.svg";
import Clap from "../assets/icons/clap.svg";
import Clock from "../assets/icons/clock.svg";
import CloseCircle from "../assets/icons/close_circle.svg";
import Comment from "../assets/icons/comment.svg";
import Copy from "../assets/icons/copy.svg";
import Download from "../assets/icons/download.svg";
import Driver from "../assets/icons/driver.svg";
import Edit from "../assets/icons/edit.svg";
import EditDraw from "../assets/icons/edit_draw.svg";
import ElementEqual from "../assets/icons/element_equal.svg";
import Flame from "../assets/icons/flame.svg";
import Gallery from "../assets/icons/gallery.svg";
import Heart from "../assets/icons/heart.svg";
import HeartCircle from "../assets/icons/heart_circle.svg";
import HeartHand from "../assets/icons/heart_hand.svg";
import HeartTick from "../assets/icons/heart_tick.svg";
import InfoCircle from "../assets/icons/info_circle.svg";
import LampCharge from "../assets/icons/lamp_charge.svg";
import Location from "../assets/icons/location.svg";
import LockCircle from "../assets/icons/lock_circle.svg";
import MessageText from "../assets/icons/message_text.svg";
import Mic from "../assets/icons/mic.svg";
import Microphone2 from "../assets/icons/microphone_2.svg";
import MinusSquare from "../assets/icons/minus_square.svg";
import Mobile from "../assets/icons/mobile.svg";
import Moon from "../assets/icons/moon.svg";
import Music from "../assets/icons/music.svg";
import MusicGrp from "../assets/icons/music_grp.svg";
import MusicPlaylist from "../assets/icons/music_playlist.svg";
import Notification from "../assets/icons/notification.svg";
import PlusSquare from "../assets/icons/plus_square.svg";
import ReceiptSquare from "../assets/icons/receipt_square.svg";
import Scanning from "../assets/icons/scanning.svg";
import ScreenMirror from "../assets/icons/screen_mirror.svg";
import SearchNormal from "../assets/icons/search_normal.svg";
import SendMessage from "../assets/icons/send_message.svg";
import Share from "../assets/icons/share.svg";
import ShoppingCart from "../assets/icons/shopping_cart.svg";
import Sms from "../assets/icons/sms.svg";
import Sort from "../assets/icons/sort.svg";
import Star from "../assets/icons/star.svg";
import StarFill from "../assets/icons/star_fill.svg";
import StarFillHalf from "../assets/icons/star_fill_half.svg";
import Sun from "../assets/icons/sun.svg";
import Tag from "../assets/icons/tag.svg";
import TaskSquare from "../assets/icons/task_square.svg";
import Text from "../assets/icons/text.svg";
import Trash from "../assets/icons/trash.svg";
import Twitter from "../assets/icons/twitter.svg";
import UserOctagon from "../assets/icons/user_octagon.svg";
import UserSquare from "../assets/icons/user_square.svg";
import Verify from "../assets/icons/verify.svg";
import VideoCamera from "../assets/icons/video_camera.svg";
import VideoGrp from "../assets/icons/video_grp.svg";
import VideoPlay from "../assets/icons/video_play.svg";
import Whatsapp from "../assets/icons/whatsapp.svg";
import Play from "../assets/icons/play.svg";
import OTP from "../assets/icons/otp.svg";
import DonationBG from "../assets/icons/donation_bg.svg";
import Articles from "../assets/icons/articles.svg";

import EyeSlash from "../assets/icons/eye_slash.svg";
import Crown from "../assets/icons/crown.svg";
import People from "../assets/icons/people.svg";

import BBBook from "../assets/icons/bb_book.svg";
import BBBookFill from "../assets/icons/bb_book_fill.svg";
import BBHome from "../assets/icons/bb_home.svg";
import BBHomeFill from "../assets/icons/bb_home_fill.svg";
import BBMenu from "../assets/icons/bb_menu.svg";
import BBMenuFill from "../assets/icons/bb_menu_fill.svg";
import BBMusic from "../assets/icons/bb_music.svg";
import BBMusicFill from "../assets/icons/bb_music_fill.svg";
import BBVideoPlay from "../assets/icons/bb_video_play.svg";
import BBVideoPlayFill from "../assets/icons/bb_video_play_fill.svg";

import PlayerPlay from "../assets/icons/player_play.svg";
import PlayerPause from "../assets/icons/pause.svg";
import PlayerNext from "../assets/icons/next.svg";
import PlayerPrevious from "../assets/icons/previous.svg";
import PlayerRepeat from "../assets/icons/repeate_music.svg";
import PlayerRepeatOne from "../assets/icons/repeate_music_one.svg";
import PlayerShuffle from "../assets/icons/shuffle.svg";

import Backward10Seconds from "../assets/icons/backward_10_seconds.svg";
import Forward10Seconds from "../assets/icons/forward_10_seconds.svg";

export default class Icons {
  // Bottom Bar Icons
  static BBBookIcon = BBBook;
  static BBBookFillIcon = BBBookFill;
  static BBHomeIcon = BBHome;
  static BBHomeFillIcon = BBHomeFill;
  static BBMenuIcon = BBMenu;
  static BBMenuFillIcon = BBMenuFill;
  static BBMusicIcon = BBMusic;
  static BBMusicFillIcon = BBMusicFill;
  static BBVideoPlayIcon = BBVideoPlay;
  static BBVideoPlayFillIcon = BBVideoPlayFill;

  static SearchIcon = SearchIcon;
  static AlertIcon = Alert;
  static ArchiveIcon = Archive;
  static ArchiveAddIcon = ArchiveAdd;
  static ArchiveAddFillIcon = ArchiveAddFill;
  static ArchiveTickIcon = ArchiveTick;
  static ArrowDownIcon = ArrowDown;
  static ArrowLeftIcon = ArrowLeft;
  static ArrowRightIcon = ArrowRight;
  static ArrowSquareDownIcon = ArrowSquareDown;
  static ArrowUpIcon = ArrowUp;
  static BagTickIcon = BagTick;
  static BookIcon = Book;
  static BookmarkIcon = Bookmark;
  static BubbleIcon = Bubble;
  static CalendarIcon = Calendar;
  static CalendarSelectedIcon = CalendarSelected;
  static CalendarTickIcon = CalendarTick;
  static CallIcon = Call;
  static CallCallingIcon = CallCalling;
  static ChatIcon = Chat;
  static ClapIcon = Clap;
  static ClockIcon = Clock;
  static CloseCircleIcon = CloseCircle;
  static CommentIcon = Comment;
  static CopyIcon = Copy;
  static DownloadIcon = Download;
  static DriverIcon = Driver;
  static EditIcon = Edit;
  static EditDrawIcon = EditDraw;
  static ElementEqualIcon = ElementEqual;
  static FlameIcon = Flame;
  static GalleryIcon = Gallery;
  static HeartIcon = Heart;
  static HeartCircleIcon = HeartCircle;
  static HeartHandIcon = HeartHand;
  static HeartTickIcon = HeartTick;
  static InfoCircleIcon = InfoCircle;
  static LampChargeIcon = LampCharge;
  static LocationIcon = Location;
  static LockCircleIcon = LockCircle;
  static MessageTextIcon = MessageText;
  static MicIcon = Mic;
  static Microphone2Icon = Microphone2;
  static MinusSquareIcon = MinusSquare;
  static MobileIcon = Mobile;
  static MoonIcon = Moon;
  static MusicIcon = Music;
  static MusicGrpIcon = MusicGrp;
  static MusicPlaylistIcon = MusicPlaylist;
  static NotificationIcon = Notification;
  static PlusSquareIcon = PlusSquare;
  static ReceiptSquareIcon = ReceiptSquare;
  static ScanningIcon = Scanning;
  static ScreenMirrorIcon = ScreenMirror;
  static SearchNormalIcon = SearchNormal;
  static SendMessage = SendMessage;
  static ShareIcon = Share;
  static ShoppingCartIcon = ShoppingCart;
  static SmsIcon = Sms;
  static SortIcon = Sort;
  static StarIcon = Star;
  static StarFillIcon = StarFill;
  static StarFillHalfIcon = StarFillHalf;
  static SunIcon = Sun;
  static TagIcon = Tag;
  static TaskSquareIcon = TaskSquare;
  static TextIcon = Text;
  static TrashIcon = Trash;
  static TwitterIcon = Twitter;
  static UserOctagonIcon = UserOctagon;
  static UserSquareIcon = UserSquare;
  static VerifyIcon = Verify;
  static VideoCameraIcon = VideoCamera;
  static VideoGrpIcon = VideoGrp;
  static VideoPlayIcon = VideoPlay;
  static WhatsappIcon = Whatsapp;
  static PlayIcon = Play;
  static EyeSlashIcon = EyeSlash;
  static OTP = OTP;
  static CrownIcon = Crown;
  static PeopleIcon = People;
  static DonationBGIcon = DonationBG;
  static ArticlesIcon = Articles;

  static PlayerPlay = PlayerPlay;
  static PlayerPause = PlayerPause;
  static PlayerNext = PlayerNext;
  static PlayerPrevious = PlayerPrevious;
  static PlayerRepeat = PlayerRepeat;
  static PlayerRepeatOne = PlayerRepeatOne;
  static PlayerShuffle = PlayerShuffle;

  static Backward10Seconds = Backward10Seconds;
  static Forward10Seconds = Forward10Seconds;
}
