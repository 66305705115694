import React from "react";

export default function App() {
  return (
    <>
      <div className="h-screen flex items-center justify-center text-center text-2xl font-semibold">
        Oops sorry !
        <br />
        <br />
        <br />
        You did a crash landing ...
      </div>
    </>
  );
}
