import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Icons from "../../components/Icons";
import Moment from "react-moment";
import moment from "moment";
import { isMobile } from "react-device-detect";

export const BaseUrl = process.env.REACT_APP_HOST_URL;

function HeaderSection(props) {
  const {
    id,
    token,
    content,
    isLoading,
    messageData,
    onRectNativeMessageSend,
    imgSize,
  } = props;

  console.log("HeaderDetails - props", props);

  const SmallRowWidget = ({ icon, title, value }) => {
    return (
      <div className="flex flex-col rounded-xl border-1 py-2 shadow-md bg-slate-50">
        <div className="flex flex-row my-1">
          {icon && (
            <div className="whitespace-pre-wrap">
              <img
                src={icon}
                className="h-32 w-32 mt-0.5 rounded-md"
                alt="logo"
              />
            </div>
          )}
          {title && (
            <p className="mt-2 text-black font-primary-bold text-3xl items-center ml-4 justify-center align-middle">
              {title}
            </p>
          )}
        </div>
        {value && (
          <p className="items-center font-primary-regular text-sm text-black ml-2 text-left ">
            {value}
          </p>
        )}
      </div>
    );
  };

  const NormalRowWidget = ({ icon, title, value }) => {
    return (
      <div className="my-1">
        <div className="whitespace-pre-wrap">
          <img
            src={icon}
            className="rounded-lg"
            style={{
              minWidth: "46%",
              maxWidth: "100%",
              width: "100%",
            }}
          />
          <p className="mt-2 text-black font-primary-bold text-3xl items-center ml-4 justify-center align-middle">
            {title}
          </p>
        </div>
        {value ? (
          <div className="items-center">
            <p className="font-primary-regular text-sm text-black">{value}</p>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  };

  return (
    <div className="items-center">
      {imgSize && imgSize === "small" ? (
        <SmallRowWidget icon={content.banner_image_url} title={content.title} />
      ) : (
        <NormalRowWidget
          icon={content.banner_image_url}
          title={
            <div>
              {content &&
                content?.widget_list &&
                content?.widget_list.length > 0 && (
                  <div className="bg-primary-50 rounded-lg w-fit p-1 px-2 mt-5">
                    <p className="text-black font-primary-regular text-[10px] tracking-widest uppercase">
                      {moment(content.widget_list[0]?.agenda_date).isAfter(
                        new Date(),
                        "day"
                      )
                        ? "Upcoming"
                        : "Past"}
                    </p>
                  </div>
                )}
              {content.title}
            </div>
          }
        />
      )}
    </div>
  );
}

export default HeaderSection;
