import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import Button from "../../../components/button";
import { ReactComponent as SendMessage } from "../../../assets/icons/send_message.svg";
import moment from "moment";

import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import TextareaAutosize from "react-autosize-textarea";
import CustomTextArea from "../CustomTextArea";
import MessageContract from "../../../MessageContract";

export const BaseUrl = process.env.REACT_APP_HOST_URL;
export const CmsUrl = process.env.REACT_APP_CMS_APP_URL;

function ReviewInput(props) {
  const {
    id,
    content,
    userId,
    rating,
    parentComment,
    token,
    isLoading,
    onLoading,
    onRefreshRating,
    onReactNativeMessageSend,
  } = props;

  const [showReplyInput, setShowReplyInput] = useState(false);
  const [comment, setComment] = useState("");
  const [mode, setMode] = useState("ADD");
  const [errorMessage, setErrorMessage] = useState("");

  const submitComment = () => {
    if (comment === "") {
      setErrorMessage("Comment is required !");
      return;
    }

    onLoading(true);
    let data = [];
    if (mode === "ADD") {
      data = [
        {
          id_parent: parentComment.id_comment,
          text: comment,
        },
      ];
    } else {
      data = [
        {
          ...parentComment,
          text: comment,
          comment_list: undefined,
        },
      ];
    }

    // console.log("Submit Data", data);

    const headers =
      !token || token == null
        ? {}
        : {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          };
    fetch(
      `${BaseUrl}/nj/jyot/app/analytics/user_content_rating/comment/${rating.id_analytics_user_content_rating_comment}`,
      {
        method: mode === "ADD" ? "post" : "put",
        headers,
        body: JSON.stringify(data),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log("Data:", data);
        if (data?.status === "SUCCESS") {
          onReactNativeMessageSend(
            MessageContract.AppRequest(MessageContract.TypeEnum.REFRESH, {
              id: id,
            })
          );
          onLoading(false);
          onRefreshRating();
        } else {
          setErrorMessage(data?.item.message || "");
          onLoading(false);
        }
      })
      .catch((err) => {
        // console.log(err.message);
        setErrorMessage(err.message);
        onLoading(false);
      });
  };

  // console.log(
  //   "Content - content, review",
  //   content,
  //   rating,
  //   comment,
  //   cursorPosition
  // );

  const isDisabled = comment === null || comment.trim() === "";

  const currentDate = moment();
  const commentCreateDate = moment(parentComment.cr_dt);

  // console.log(
  //   "now",
  //   currentDate,
  //   "cr_dt",
  //   commentCreateDate,
  //   "diff",
  //   currentDate.diff(commentCreateDate, "days")
  // );
  let showEditButton =
    rating.comment_user_list[parentComment.user_id].utype == "ADMIN"
      ? true //Always edit allowed for Admin
      : parentComment.user_id === userId &&
        currentDate.diff(commentCreateDate, "days") == 0; //For User within 1 day edit comment allowed

  return (
    <>
      <div className="flex flex-row">
        {showEditButton && (
          <>
            <div
              className="mb-6 mt-0 text-left text-sm font-primary-medium md:hover:underline text-cyan-400 tracking-wider"
              onClick={() => {
                setMode("EDIT");
                setComment(parentComment.text);
                setShowReplyInput(true);
              }}
            >
              Edit
            </div>
            <div className=" border-2 border-slate-200 mx-4 mt-1 h-4 "></div>
          </>
        )}
        <div
          className="mb-6 mt-0 text-left text-sm font-primary-medium md:hover:underline text-slate-400 tracking-wider"
          onClick={() => {
            setMode("ADD");
            setComment("");
            setShowReplyInput(true);
          }}
        >
          Reply
        </div>
      </div>
      {showReplyInput && (
        <div className="mb-6">
          <div className="text-left text-sm flex w-full">
            <CustomTextArea
              value={comment}
              onChange={(value) => setComment(value)}
            />
            <button
              class={`font-bold p-2 px-4 uppercase bg-orange-50`}
              onClick={() => submitComment()}
            >
              <SendMessage width={20} height={20} />
            </button>
          </div>
          <div className="text-orange-400">{errorMessage}</div>
        </div>
      )}
    </>
  );
}

export default ReviewInput;
