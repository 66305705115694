import { Outlet } from "react-router-dom";
import { isMobile } from "react-device-detect";
import React, { useEffect, useState } from "react";
import HeaderSection from "../header";
import moment from "moment";
import CustomIframe from "./CustomIframe";

export const BaseUrl = process.env.REACT_APP_HOST_URL;
export const CmsUrl = process.env.REACT_APP_CMS_APP_URL;

function PageDetails(props) {
  const { id, token, content } = props;

  console.log("PageDetails", props);
  useEffect(() => {
    window.location.href = `${CmsUrl}/readonly/index.html?pageId=${id}&token=${token}`;
  }, []);
  return <Outlet />;
}

export default PageDetails;
