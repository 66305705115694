import React, { useState } from "react";

function ReadMore(props) {
  const { text, className, textLength } = props;
  const [expand, setExpand] = useState(false);

  const renderText = (text) => {
    return (
      <div
        className={`${className} text-sm break-words`}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    );
  };

  return text && textLength && text.length > textLength ? (
    <div>
      {expand ? renderText(text) : renderText(text.substring(0, textLength))}
      {!expand && (
        <div
          className="text-xs text-gray-500 p-0 pt-1"
          onClick={() => setExpand(true)}
        >
          Read More &gt;
        </div>
      )}
      {expand && (
        <div
          className="text-xs text-gray-500 p-0 pt-1"
          onClick={() => setExpand(false)}
        >
          &lt; Read Less
        </div>
      )}
    </div>
  ) : (
    renderText(text)
  );
}

export default ReadMore;
