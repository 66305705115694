import React from "react";

export default function InterestBubble(props) {
  return (
    <div
      onClick={props.onClick}
      className={`flex justify-center items-center rounded-full w-full h-full border relative ${
        props.isSelected ? "border-primary" : "border-gray-300"
      } cursor-pointer`}
    >
      {props.isSelected ? (
        <div className="absolute h-11 w-11 bg-primary-100 rounded-full -right-1 -top-1 flex justify-center items-center">
          <div className="h-8 w-8 bg-primary rounded-full flex justify-center items-center border-[6px] border-white">
            <span class="material-symbols-outlined text-white">
              check_small
            </span>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          transition: "opacity 0.1s ease",
          opacity: props.bubbleSize > 50 ? 1 : 0,
          pointerEvents: "none",
        }}
      >
        <img src={props.thumbnail_image_url} className="h-10 w-10" alt="logo" />
        <p className="font-primary-bold text-sm mt-2 text-center">
          {props.title}
        </p>
      </div>
    </div>
  );
}
