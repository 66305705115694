import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useLocation } from "react-router-dom";
import QuizDetails from "./quiz";
import EventDetails from "./event";
// import SeriesDetails from "./series";
import PageDetails from "./post";

export const BaseUrl = process.env.REACT_APP_HOST_URL;

function CmsContent(props) {
  const search = useLocation().search;
  const params = new URLSearchParams(search);
  const token = params.get("token");
  const id = params.get("id");
  const [content, setContent] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [messageData, setMessageData] = useState(true);

  useEffect(() => {
    console.log(search, params, id);

    if (id && id != null) {
      const headers =
        !token || token == null ? {} : { Authorization: "Bearer " + token };
      fetch(`${BaseUrl}/nj/jyot/app/cms_content/` + id, { headers })
        .then((res) => res.json())
        .then((data) => {
          setContent(data?.item || {});
          setIsLoading(false);
        })
        .catch((err) => {
          // console.log(err.message);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("message", onRectNativeMessageReceived);
    document.addEventListener("message", onRectNativeMessageReceived);
    return () => {
      window.removeEventListener("message", onRectNativeMessageReceived);
      document.removeEventListener("message", onRectNativeMessageReceived);
    };
  }, []);

  const onRectNativeMessageSend = (data) => {
    setMessageData(data);
    window?.ReactNativeWebView?.postMessage(JSON.stringify(data));
  };

  const onRectNativeMessageReceived = (message) => {
    try {
      const data = JSON.parse(message.data);
      setMessageData(data);
    } catch (error) {}
  };

  console.log("Content - content", content);
  const childProps = {
    id: id,
    token: token,
    content: content,
    isLoading: isLoading,
    messageData: messageData,
    onRectNativeMessageSend: onRectNativeMessageSend,
  };

  return isLoading ? (
    <div className="h-screen flex items-center justify-center text-center text-2xl font-semibold">
      <div
        className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
        role="status"
      ></div>
      <span className="ml-5">Please wait, loading...</span>
    </div>
  ) : content ? (
    <div
      className={`${
        isMobile ? "overflow-y-scroll" : ""
      } justify-center flex p-4`}
    >
      {content.content_type === "QUIZ" ? (
        <QuizDetails {...childProps} />
      ) : content.content_type === "EVENT" ? (
        <EventDetails {...childProps} />
      ) : (
        //SERIES Supported from App side
        // ) : content.content_type === "SERIES" ? (
        //   <SeriesDetails {...childProps} />
        <PageDetails {...childProps} />
      )}
      {/* //FAQ
      //PP
      //T&C
      //Contact */}
    </div>
  ) : (
    <>
      <div className="h-screen flex items-center justify-center text-center text-2xl font-semibold">
        Oops sorry !
        <br />
        <br />
        <br />
        Unable to fetch content, please try again...
      </div>
    </>
  );
}

export default CmsContent;
