import React, { useState } from "react";

function Button(props) {
  const { onClick, simple, title, icon, className, ...prop } = props;

  const btnClassName =
    simple === true
      ? `bg-white hover:bg-orange-50 text-orange-600 font-bold py-2 px-4 border-2 border-orange-300 rounded-md w-full ${className}`
      : `bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 border border-orange-700 rounded-md w-full  ${className}`;

  console.log("Icon- ", icon);
  return (
    <button
      className={btnClassName}
      // className={"border-2 border-orange-300 rounded-md "}
      onClick={() => {
        if (onClick) onClick();
      }}
      {...prop}
    >
      <div className=" flex flex-row justify-center items-center">
        {title}
        {icon}
      </div>
    </button>
  );
}

export default Button;
