import React, { useState, useEffect } from "react";
import BubbleUI from "react-bubble-ui";
import "react-bubble-ui/dist/index.css";
import UpdateBubble from "./updateBubble";

export const BaseUrl = process.env.REACT_APP_HOST_URL;

export default function Updates(props) {
  const [updatesData, setUpdatesData] = useState([]);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    fetch(`${BaseUrl}/nj/jyot/app/cms_content/updates`)
      .then((res) => res.json())
      .then((data) => {
        const items = data?.item || [];
        // console.log(items);
        setUpdatesData(items);
      })
      .catch((err) => {
        // console.log(err.message);
      });
  }, []);

  useEffect(() => {
    window.addEventListener("message", onRectNativeMessage);

    return () => {
      window.removeEventListener("message", onRectNativeMessage);
    };
  }, []);

  const onRectNativeMessage = (message) => {
    try {
      const data = JSON.parse(message.data);

      // const s = selected;
      // if (s[company.name]) {
      //   delete s[company.name];
      // } else {
      //   s[company.name] = company;
      // }
      setSelected(data);
    } catch (error) {}
  };

  const getUpdateBubbles = () => {
    return updatesData.map((update, i) => {
      return (
        <UpdateBubble
          {...update}
          key={i}
          isSelected={selected?.content_type === update?.content_type}
          onClick={() => {
            if (window.ReactNativeWebView) {
              window.ReactNativeWebView.postMessage(JSON.stringify(update));
            }
            setSelected(update);
          }}
        />
      );
    });
  };

  const [options, setOptions] = useState({
    size: 140,
    minSize: 80,
    gutter: 10,
    provideProps: true,
    numCols: 3,
    fringeWidth: 80,
    yRadius: 200,
    xRadius: 100,
    cornerRadius: 50,
    showGuides: false,
    compact: true,
    gravitation: 5,
  });

  return (
    <React.Fragment>
      <BubbleUI className="bubbleUI" options={options}>
        {getUpdateBubbles()}
      </BubbleUI>
    </React.Fragment>
  );
}
