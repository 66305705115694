import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Icons from "../../components/Icons";
import Moment from "react-moment";
import moment from "moment";
import HeaderSection from "../header";
import Question from "./question";
import Button from "../../components/button";

export const BaseUrl = process.env.REACT_APP_HOST_URL;

function QuizDetails(props) {
  const {
    id,
    token,
    content,
    isLoading,
    messageData,
    onRectNativeMessageSend,
  } = props;

  const questionList =
    content && content?.widget_list && content?.widget_list[0]?.quiz_details
      ? content?.widget_list[0]?.quiz_details
      : [];

  const [currentQuestionIdx, setCurrentQuestionIdx] = useState(0);
  const [selectedAnswerIdxMap, setSelectedAnswerIdxMap] = useState({});
  const [showValidation, setShowValidation] = useState(false);

  console.log("QuizDetails - props", props);

  const RowWidget = ({ icon, title, value }) => {
    return (
      <div className="flex flex-row my-1">
        <div className="w-4/5 whitespace-pre-wrap">
          {icon && <img src={icon} className="h-4 w-4" alt="logo" />}
          <p className="font-primary-regular text-sm text-black ml-4">
            {title}
          </p>
        </div>
        {value ? (
          <div className="items-center">
            <p className="font-primary-regular text-sm text-black ml-2 text-left ">
              {value}
            </p>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  };

  const onSelectionChange = (selecteAnswerIdx) => {
    const newSelectedAnswerIdxMap = JSON.parse(
      JSON.stringify(selectedAnswerIdxMap)
    );
    newSelectedAnswerIdxMap[questionList[currentQuestionIdx].id] =
      selecteAnswerIdx;
    setSelectedAnswerIdxMap(newSelectedAnswerIdxMap);
  };

  const QuizDetails = (props) => {
    console.log("QuizDetails", questionList);
    const selectedAnswerIdx = getSelectedAnswer();
    if (questionList.length > 0) {
      console.log("questionInfo", questionList[props.questionIndex]);
      return (
        <Question
          idx={props.questionIndex}
          questionInfo={questionList[props.questionIndex]}
          selectedAnswerIdx={selectedAnswerIdx}
          onSelectionChange={onSelectionChange}
        />
      );
    }
    return <></>;
  };

  const getSelectedAnswer = (idx) => {
    return selectedAnswerIdxMap[questionList[idx]]
      ? selectedAnswerIdxMap[questionList[idx].id]
      : -1;
  };

  const submitQuizAnswers = () => {
    //Submit Quiz
  };

  const QuizButton = (props) => {
    return (
      <div
        className="my-1 items-center whitespace-nowrap"
        onClick={props.btnHandler}
      >
        <Button onClick={() => {}} title={props.btnLabel}></Button>
      </div>
    );
  };

  return questionList.length > 0 ? (
    <div className=" items-center w-full px-2 md:w-2/3 md:p-0">
      <HeaderSection {...props} imgSize="small" />
      <div className="flex flex-row justify-between mt-5">
        <div>
          {currentQuestionIdx > 0 ? (
            <QuizButton
              btnLabel={<>&lt; Previous</>}
              btnHandler={() => setCurrentQuestionIdx(currentQuestionIdx - 1)}
            />
          ) : (
            <div style={{ width: "75px" }}></div>
          )}
        </div>
        <div className="text-sm text-gray-500 text-center ">
          Question <br /> {currentQuestionIdx + 1} of {questionList.length + 1}
        </div>
        <div>
          {currentQuestionIdx < questionList.length ? (
            <QuizButton
              btnLabel={<>Next &gt;</>}
              btnHandler={() => {
                if (getSelectedAnswer(currentQuestionIdx) == -1) {
                  setShowValidation(true);
                } else {
                  setCurrentQuestionIdx(currentQuestionIdx + 1);
                }
              }}
            />
          ) : (
            <RowWidget
              title={
                <QuizButton
                  btnLabel={<>Submit Quiz</>}
                  btnHandler={() => {
                    let isValid = true;
                    selectedAnswerIdxMap.map((ques, index) => {
                      if (isValid == true && getSelectedAnswer(index) === -1) {
                        isValid = false;
                      }
                    });
                    if (isValid === false) {
                      setShowValidation(true);
                    } else {
                      submitQuizAnswers();
                    }
                  }}
                />
              }
            />
          )}
        </div>
      </div>
      <QuizDetails questionIndex={currentQuestionIdx} />
    </div>
  ) : (
    <>
      <div className="h-screen flex items-center justify-center text-center text-2xl font-semibold">
        Oops sorry !
        <br />
        <br />
        <br />
        Cannot load content, please try again...
      </div>
    </>
  );
}

export default QuizDetails;
