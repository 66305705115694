import React, { useEffect, useState } from "react";
import BubbleUI from "react-bubble-ui";
import "react-bubble-ui/dist/index.css";
import InterestBubble from "./InterestBubble";

export const BaseUrl = process.env.REACT_APP_HOST_URL;

export default function Interest(props) {
  const [interestData, setInterestData] = useState([]);
  const [selected, setSelected] = useState({});

  useEffect(() => {
    fetch(`${BaseUrl}/nj/jyot/app/cms_content/interest`)
      .then((res) => res.json())
      .then((data) => {
        const items = data?.item?.item || [];
        // console.log(items);
        setInterestData(items);
      })
      .catch((err) => {
        // console.log(err.message);
      });
  }, []);

  useEffect(() => {
    window.addEventListener("message", onRectNativeMessage);
    document.addEventListener("message", onRectNativeMessage);
    return () => {
      window.removeEventListener("message", onRectNativeMessage);
      document.removeEventListener("message", onRectNativeMessage);
    };
  }, []);

  const onRectNativeMessage = (message) => {
    try {      
      const data = JSON.parse(message.data);
      setSelected(data);
    } catch (error) {
      
    }
  };

  const [options, setOptions] = useState({
    size: 140,
    minSize: 80,
    gutter: 28,
    provideProps: true,
    numCols: 4,
    fringeWidth: 160,
    yRadius: 100,
    xRadius: 120,
    cornerRadius: 50,
    showGuides: false,
    compact: true,
    gravitation: 5,
  });

  // console.log('SELECTED >> ', selected);

  return (
    <React.Fragment>
      {/* <div>BASE URL :: {BaseUrl}</div>
      <div>Testing INTEREST DATA LENGTH :: {interestData?.length}</div>
      <div>Testing:: {JSON.stringify(selected)}</div> */}
      <BubbleUI className="bubbleUI" options={options}>
        {interestData && interestData.length > 0 && (
          interestData.map((interest, i) => {
            const isSelected = selected[interest.id_reference_interest];
            return (
              <InterestBubble
                {...interest}
                key={i}
                isSelected={isSelected}
                onClick={() => {

                  // const s = { ...selected };
                  // if (s[interest.id_reference_interest]) {
                  //   delete s[interest.id_reference_interest];
                  // } else {
                  //   s[interest.id_reference_interest] = interest;
                  // }
                  window?.ReactNativeWebView?.postMessage(JSON.stringify(interest));
                  // setSelected(s);
                }}
              />
            );
          })
        )}
      </BubbleUI>
    </React.Fragment>
  );
}
