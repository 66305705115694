import { Outlet } from "react-router-dom";
import React, { useEffect, useState } from "react";

export const BaseUrl = process.env.REACT_APP_HOST_URL;
export const CmsUrl = process.env.REACT_APP_CMS_APP_URL;

function StaticDetails(props) {
  const { view_type } = props;
  const [notFound, setNotFound] = useState(false);
  console.log("StaticDetails", props);

  useEffect(() => {
    fetch(`${BaseUrl}/nj/jyot/app/misc_content/static/${view_type}`)
      .then((res) => res.json())
      .then((data) => {
        const items = data || [];
        setNotFound(items.length == 0);
        if (!notFound) {
          window.location.href = `${CmsUrl}/info/index.html?pageId=${items[0].id_cms_content}`;
        }
      })
      .catch((err) => {
        setNotFound(true);
      });
  }, []);

  if (notFound) {
    return (
      <>
        <div className="h-screen flex items-center justify-center text-center text-2xl font-semibold">
          Oops sorry !
          <br />
          <br />
          <br />
          Page not found, please try again.
        </div>
      </>
    );
  } else {
    return <Outlet />;
  }
}

export default StaticDetails;
