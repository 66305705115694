function UpdateBubble(props) {
  return (
    <div
      onClick={props.onClick}
      className={`flex justify-center items-center rounded-full w-full h-full border relative ${
        props.isSelected ? "border-primary shadow-lg" : "border-gray-300"
      } cursor-pointer`}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          transition: "opacity 0.1s ease",
          opacity: props.bubbleSize > 50 ? 1 : 0,
          pointerEvents: "none",
          textAlign: "center",
        }}
      >
        <p className="font-primary-bold text-4xl text-primary">{props.count}</p>
        <div className="flex flex-row items-center justify-center mt-1">
          <img
            src={props.thumbnail_image_url}
            className=""
            alt="logo"
            style={{ justifyContent: "flex-start" }}
          />
          <pre className="font-primary-bold text-sm ml-2">
            {props.title.replace(/ /g, "\r\n")}
          </pre>
        </div>
      </div>
    </div>
  );
}

export default UpdateBubble;
